import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../images/ch.svg"


const Header = ({ siteTitle }) => (
  <header
    style={{
      background: ` #243914`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link
      to="/"
      >
        <Logo data-sal="slide-right" data-sal-delay="400" data-sal-easing="ease" />
      </Link>
      <h2 class="sitetitle" style={{ margin: 0, float: 'right', }} data-sal="slide-left" data-sal-delay="600" data-sal-easing="ease">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h2>


    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
